<template>
  <a-col :sm="24" :xs="24" class="mb-24">
    <a-card :title="title">
      <div class="pageTittle">
      <DateFilter :Buttons="filterButtons" @clickButtons="showButton(deviceId)" :custom="false" :commit="commit" />
      </div>
      <a-tabs v-if="!isEscalationAction">
        <a-tab-pane key="1" tab="Table" >
          <VitalsTable  :columns="tableColumns" :data="tableData" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Graph">
          <ApexChart type="area" height="350" :options="chartOptions" :series="chartSeries" />
        </a-tab-pane>
      </a-tabs>
      <a-tabs v-if="isEscalationAction">
      <a-tab-pane key="1" tab="Graph">
          <ApexChart type="area" height="350" :options="chartOptions" :series="chartSeries" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Table" >
          <VitalsTable  :columns="tableColumns" :data="tableData" />
        </a-tab-pane>
        
      </a-tabs>
      <template #extra v-if="!isEscalationAction">
        <a>
          <a-button class="btn blackBtn" @click="showModal">
            <PlusOutlined/>
          </a-button>
        </a>
      </template>
    </a-card>
  </a-col>
</template>

<script>
import {
  PlusOutlined,
} from "@ant-design/icons-vue";
import VitalsTable from "@/components/patients/patientSummary/common/VitalsTable";
import ApexChart from "@/components/common/charts/ApexChart";
import DateFilter from "@/components/common/DateFilter"
import {  onMounted, onUnmounted, ref, watchEffect } from 'vue-demi';
import { useStore } from 'vuex';
import { dayWeekMonthdate } from '@/commonMethods/commonMethod';
import { useRoute } from 'vue-router';

export default {
  components: {
    PlusOutlined,
    VitalsTable,
    ApexChart,
    DateFilter,
  },
  props: {
    title: {
      type: String
    },
    deviceId: {
      type: Number
    },
    activeKey: {
      type: String
    },
    tableColumns: {
      type: Array
    },
    tableData: {
      type: Array
    },
    chartOptions: {
      type: Array
    },
    chartSeries: {
      type: Array
    },
    filterButtons: {
      type: Object
    },
    commit: {
      type: String
    },
    isEscalationAction:{
      type:Boolean
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const route = useRoute()

    const showModal = () => {
      emit('showModal')
    }

    // const filteredVitals = computed(() => {
    //   return store.state.patients.filteredVitals
    // })
    
    const bloodOxygenTimeline = store.getters.bloodOxygenTimeline
    const bloodGlucoseTimeline = store.getters.bloodGlucoseTimeline
    const bloodPressureTimeline = store.getters.bloodPressureTimeline
    
    function getVitals() {
      if(props.commit=="bloodOxygenTimeline"){
        console.log("bloodOxygenTimeline.value",bloodOxygenTimeline.value)
        bloodOxygenTimeline.value ? apiCall(bloodOxygenTimeline.value, 100) : store.dispatch("timeLine", {
          id: 332,
          commit: "bloodOxygenTimeline"
        }).then(() => {
          apiCall(bloodOxygenTimeline.value, 100)
        })
      
    }else if(props.commit=="bloodGlucoseTimeline"){
      console.log("bloodGlucoseTimeline.value",bloodGlucoseTimeline.value)
        bloodGlucoseTimeline.value ? apiCall(bloodGlucoseTimeline.value, 101) : store.dispatch("timeLine", {
          id: 332,
          commit: "bloodGlucoseTimeline"
        }).then(() => {
          apiCall(bloodGlucoseTimeline.value, 101)
        })
    }else  if(props.commit=="bloodPressureTimeline"){
      console.log("bloodPressureTimeline.valu",bloodPressureTimeline.value)
        bloodPressureTimeline.value ? apiCall(bloodPressureTimeline.value, 99) : store.dispatch("timeLine", {
          id: 332,
          commit: "bloodPressureTimeline"
        }).then(() => {
          apiCall(bloodPressureTimeline.value, 99)
        })
    }
    }

    watchEffect(() => {
      
      
    })
    const interval = ref('')
    onMounted(() => {
      if(route.name == 'PatientSummary') {
          getVitals()
        }
        interval.value =  setInterval(() => {
        if(route.name == 'PatientSummary') {
          getVitals()
        }
      }, 10000)
      
    })
     onUnmounted(()=>{
      clearInterval(interval.value);
     })
    function apiCall(data, deviceId) {
      let dateFormat = dayWeekMonthdate(data)
      let dateFilter = dateFormat ? "&fromDate=" + dateFormat.fromDate + "&toDate=" + dateFormat.toDate : ''
      if(deviceId) {
        store.dispatch("patientVitals", {
          patientId: route.params.udid,
          deviceType: deviceId,
          filter: dateFilter,
        });
      }
      else {
        store.dispatch("patientVitals", {
          patientId: route.params.udid,
          deviceType: 99,
          filter: dateFilter,
        });
        store.dispatch("patientVitals", {
          patientId: route.params.udid,
          deviceType: 100,
          filter: dateFilter,
        });
        store.dispatch("patientVitals", {
          patientId: route.params.udid,
          deviceType: 101,
          filter: dateFilter,
        });
      }
    }

    function showButton(deviceId) {
      //store.commit('filteredVitals', true)
      if(deviceId == 99) {
        apiCall(bloodPressureTimeline.value, deviceId)
      }
      if(deviceId == 100) {
        apiCall(bloodOxygenTimeline.value, deviceId)
      }
      if(deviceId == 101) {
        apiCall(bloodGlucoseTimeline.value, deviceId)
      }
    }

    return {
      showModal,
      showButton,
    }
  }
}
</script>

<style lang="scss" scoped>
.active {
  background-color: #777;
  color: #fff;
}
.pageTittle {
  margin: 0;
  .filter {
    position: initial;
    text-align: right;
  }
}
</style>